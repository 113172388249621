import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import {GetALEDataResponse} from "src/models/GetALEDataResponse";
import {GetCompanyCodesResponse} from "src/models/GetCompanyCodesResponse";

export default class ALEDataService {
    async getCompanyCodes() {
        let getCompanyCodesResponse = undefined as unknown as GetCompanyCodesResponse;
        let getCompanyCodesError = '';

        try {
          const response = await apiService.getCompanyCodes();
          getCompanyCodesResponse = await response.json() as GetCompanyCodesResponse;
        } catch (ex) {
          getCompanyCodesError = ErrorUtils.getMessage(ex);
        }

        return {
          getCompanyCodesResponse,
          getCompanyCodesError
        }
    }
    async getALEData(companyCode: string) {
        let getALEDataResponse = undefined as unknown as GetALEDataResponse;
        let getALEDataError = '';

        try {
          const response = await apiService.getALEData(companyCode);
          getALEDataResponse = await response.json() as GetALEDataResponse;
        } catch (ex) {
          getALEDataError = ErrorUtils.getMessage(ex);
        }

        return {
          getALEDataResponse,
          getALEDataError
        }
    }
}


