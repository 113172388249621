import React, {useContext} from "react";
import {
    Autosuggest,
    Button,
    ColumnLayout,
    DatePicker,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    RadioGroup,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react";
import {isEquals, RegistrationData} from "src/models/data-registration/RegistrationData";
import ServiceCollection from "src/services/ServiceCollection";
import {useHistory, useLocation} from "react-router-dom";
import {GlobalAppContext} from "src/components/App";
import CONSTANTS from "src/utils/constants";
import {formatDate} from "src/utils/DateUtils";
import {customHelpPanel} from "src/components/CustomComponents";
import {getTaxProductCategoryNames, getTaxProductNames, getDataFromMDM} from "src/utils/MDMUtils";

export default function EditData(props: { services: ServiceCollection, userAlias: string }) {
    const {isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping,
        categorySubcategoriesMapping, setCategorySubcategoriesMapping} = useContext(GlobalAppContext);
    const history = useHistory();

    // If user is viewer, redirect to home page
    if (isViewer) {
        history.push({
            pathname: '/',
        });
        return <></>;
    }
    const {services, userAlias} = props;
    const location = useLocation<any>();
    const initialRegistrationData = location.state as RegistrationData;
    const [loading, setIsLoading] = React.useState(false);

    // Registration Data fields
    const [atpCalculation, setATPCalculation] = React.useState(String(initialRegistrationData.atpCalculation) ?? '');
    const [currency, setCurrency] = React.useState(initialRegistrationData.currency ?? '');
    const [effectiveFromDate, setEffectiveFromDate] = React.useState(initialRegistrationData.effectiveFromDate ?? '');
    const [effectiveToDate, setEffectiveToDate] = React.useState(initialRegistrationData.effectiveToDate ?? '');
    const [workbookName, setWorkbookName] = React.useState(initialRegistrationData.workbookName ?? '');
    const [beat, setBeat] = React.useState(initialRegistrationData.beat ?? '');
    const [description, setDescription] = React.useState(initialRegistrationData.description ?? '');
    const [comments, setComments] = React.useState(initialRegistrationData.comments ?? '');
    const [providerEntityName, setProviderEntityName] = React.useState(initialRegistrationData.providerEntityName ?? '');
    const [recipientEntityName, setRecipientEntityName] = React.useState(initialRegistrationData.recipientEntityName ?? '');
    const [providerJurisdiction, setProviderJurisdiction] = React.useState(initialRegistrationData.providerJurisdiction ?? '');
    const [recipientJurisdiction, setRecipientJurisdiction] = React.useState(initialRegistrationData.recipientJurisdiction ?? '');
    const [providerTaxRegConsideration, setProviderTaxRegConsideration] = React.useState(String(initialRegistrationData.providerTaxRegConsideration) ?? '');
    const [recipientTaxRegConsideration, setRecipientTaxRegConsideration] = React.useState(String(initialRegistrationData.recipientTaxRegConsideration) ?? '');
    const [accountingOwner, setAccountingOwner] = React.useState(initialRegistrationData.accountingOwner ?? '');
    const [taxOwner, setTaxOwner] = React.useState(initialRegistrationData.taxOwner ?? '');
    const [taxProductId, setTaxProductId] = React.useState(initialRegistrationData.taxProductId ?? '');
    const [taxProductName, setTaxProductName] = React.useState(initialRegistrationData.taxProductName ?? '');
    const [taxProductCategoryId, setTaxProductCategoryId] = React.useState(initialRegistrationData.taxProductCategoryId ?? '');
    const [taxProductCategoryName, setTaxProductCategoryName] = React.useState(initialRegistrationData.taxProductCategoryName ?? '');
    const [exportOfServices, setExportOfServices] = React.useState(String(initialRegistrationData.exportOfServices) ?? '');
    const [productNameToIdMapping, setProductNameToIdMapping] = React.useState(new Map());
    const [loadingMDMData, setLoadingMDMData] = React.useState(false);
    const [mdmResponseErrorMessage, setMDMResponseErrorMessage] = React.useState('');

    function getMDMData() {
        // If data is already loaded from React context, just return.
        if (categoryNameToIdMapping.size != 0 && categorySubcategoriesMapping.size != 0) {
            return;
        }
        getDataFromMDM(services.mdmDataService, setCategoryNameToIdMapping, setCategorySubcategoriesMapping,
            setLoadingMDMData, setMDMResponseErrorMessage);
    }

    function onTaxProductCategoryNameChange(taxProductCategoryName: string) {
        setTaxProductCategoryName(taxProductCategoryName);
        setTaxProductCategoryId(categoryNameToIdMapping.get(taxProductCategoryName) ?? '');
        setTaxProductName('');
        setTaxProductId('');
        if (categorySubcategoriesMapping.has(taxProductCategoryName)) {
            setProductNameToIdMapping(new Map(categorySubcategoriesMapping.get(taxProductCategoryName)!
                .map(taxProductSubcategory => [taxProductSubcategory.taxProductName, taxProductSubcategory.taxProductId])));
        } else {
            setProductNameToIdMapping(new Map<string, string>());
        }
    }

    function onTaxProductNameChange(taxProductName: string) {
        setTaxProductName(taxProductName);
        setTaxProductId(productNameToIdMapping.get(taxProductName) ?? '');
    }

    async function updateRegistrationData() {
        const updatedRegistrationData = getUpdatedRegistrationData();

        // If no change is made on edit page, show error message
        if (isEquals(initialRegistrationData, updatedRegistrationData)) {
            services.messageService.showErrorAutoDismissBanner(CONSTANTS.EDIT_PAGE_NO_CHANGE_ERROR_MESSAGE);
            return;
        }

        setIsLoading(true);
        const {updateRegistrationDataResponse, updateRegistrationDataError} =
            await services.registrationDataService.updateRegistrationData(updatedRegistrationData);
        setIsLoading(false);

        if (updateRegistrationDataError) {
            services.messageService.showErrorAutoDismissBanner(updateRegistrationDataError);
            return;
        }

        updatedRegistrationData.createDateTime = initialRegistrationData.createDateTime;
        // Set lastUpdatedDateTime from response
        updatedRegistrationData.lastUpdatedDateTime = updateRegistrationDataResponse.lastUpdatedDateTime;
        updatedRegistrationData.approvalWorkflowRevision = updateRegistrationDataResponse.approvalWorkflowRevision;
        updatedRegistrationData.approvalWorkflowRevisionCount = updateRegistrationDataResponse.approvalWorkflowRevision;

        history.push({
            pathname: '/show',
            state: updatedRegistrationData
        });
    }

    function getUpdatedRegistrationData() {
        const updatedRegistrationData: RegistrationData = {
            registrationNumber: initialRegistrationData.registrationNumber,
            approvalWorkflowStage: CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT,
            atpCalculation: JSON.parse(atpCalculation),
            providerCompanyCode: initialRegistrationData.providerCompanyCode,
            recipientCompanyCode: initialRegistrationData.recipientCompanyCode,
            providerTaxRegConsideration: JSON.parse(providerTaxRegConsideration),
            recipientTaxRegConsideration: JSON.parse(recipientTaxRegConsideration),
            exportOfServices: initialRegistrationData.exportOfServices,
            createdBy: initialRegistrationData.createdBy,
            createDateTime: initialRegistrationData.createDateTime,
            lastUpdatedBy: userAlias,
        };

        if (currency) {
            updatedRegistrationData.currency = currency;
        }
        
        if (effectiveFromDate) {
            updatedRegistrationData.effectiveFromDate = effectiveFromDate;
        }

        if (effectiveToDate) {
            updatedRegistrationData.effectiveToDate = effectiveToDate;
        }

        if (workbookName) {
            updatedRegistrationData.workbookName = workbookName;
        }

        if (beat) {
            updatedRegistrationData.beat = beat;
        }

        if (description) {
            updatedRegistrationData.description = description;
        }

        if (comments) {
            updatedRegistrationData.comments = comments;
        }

        if (providerEntityName) {
            updatedRegistrationData.providerEntityName = providerEntityName;
        }

        if (providerJurisdiction) {
            updatedRegistrationData.providerJurisdiction = providerJurisdiction;
        }

        if (recipientEntityName) {
            updatedRegistrationData.recipientEntityName = recipientEntityName;
        }

        if (recipientJurisdiction) {
            updatedRegistrationData.recipientJurisdiction = recipientJurisdiction;
        }

        if (accountingOwner) {
            updatedRegistrationData.accountingOwner = accountingOwner;
        }

        if (taxOwner) {
            updatedRegistrationData.taxOwner = taxOwner;
        }

        if (taxProductName) {
            updatedRegistrationData.taxProductName = taxProductName;
        }

        if (taxProductId) {
            updatedRegistrationData.taxProductId = taxProductId;
        }

        if (taxProductCategoryName) {
            updatedRegistrationData.taxProductCategoryName = taxProductCategoryName;
        }

        if (taxProductCategoryId) {
            updatedRegistrationData.taxProductCategoryId = taxProductCategoryId;
        }

        return updatedRegistrationData;
    }

    return (
        <div>
            {loading ?
                <StatusIndicator type="loading"> Loading page </StatusIndicator> :
                <Form
                    actions={
                        <Button variant="primary" data-testid="save-registration-data-button" onClick={updateRegistrationData}>
                            Save
                        </Button>
                    }
                    header={<Header variant="h1">Update Registration Data</Header>}
                >
                    <div className="edit-registration-data" >
                        <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Registration Number">
                                    <Input data-class="input-text" value={String(initialRegistrationData.registrationNumber)} disabled />
                                </FormField>
                                <FormField />
                                <FormField label="ATP Calculation">
                                    <RadioGroup
                                      onChange={({ detail }) => setATPCalculation(detail.value)}
                                      value={atpCalculation}
                                      items={[
                                          { value: "true", label: "True" },
                                          { value: "false", label: "False" },
                                      ]}
                                    />
                                </FormField>
                                <FormField label="Currency">
                                    <Input data-class="input-text" value={currency}
                                           onChange={({ detail }) => setCurrency(detail.value)} />
                                </FormField>
                                <FormField label="Effective From Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        value={effectiveFromDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveFromDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Effective To Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        value={effectiveToDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveToDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                                    <Input data-class="input-text" value={workbookName}
                                           onChange={({ detail }) => setWorkbookName(detail.value)} />
                                </FormField>
                                <FormField label="Beat" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                                    <Input data-class="input-text" value={beat}
                                           onChange={({ detail }) => setBeat(detail.value)} />
                                </FormField>
                                <FormField label="Description">
                                    <Textarea data-class="input-text" value={description}
                                           onChange={({ detail }) => setDescription(detail.value)} />
                                </FormField>
                                <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                                    <Textarea data-class="input-text" data-testid="comments-input" value={comments}
                                           onChange={({ detail }) => setComments(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Party Information" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}>
                                    <Input data-class="input-text" value={initialRegistrationData.providerCompanyCode} disabled />
                                </FormField>
                                <FormField label="Recipient Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}>
                                    <Input data-class="input-text" value={initialRegistrationData.recipientCompanyCode} disabled />
                                </FormField>
                                <FormField label="Provider Entity Name">
                                    <Input data-class="input-text" value={providerEntityName}
                                           onChange={({ detail }) => setProviderEntityName(detail.value)} />
                                </FormField>
                                <FormField label="Recipient Entity Name">
                                    <Input data-class="input-text" value={recipientEntityName}
                                           onChange={({ detail }) => setRecipientEntityName(detail.value)} />
                                </FormField>
                                <FormField label="Provider Jurisdiction">
                                    <Input data-class="input-text" value={providerJurisdiction}
                                           onChange={({ detail }) => setProviderJurisdiction(detail.value)} />
                                </FormField>
                                <FormField label="Recipient Jurisdiction">
                                    <Input data-class="input-text" value={recipientJurisdiction}
                                           onChange={({ detail }) => setRecipientJurisdiction(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Registration Data Contributors" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Accounting Owner">
                                    <Input data-class="input-text" value={accountingOwner}
                                           onChange={({ detail }) => setAccountingOwner(detail.value)} />
                                </FormField>
                                <FormField label="Tax Owner">
                                    <Input data-class="input-text" value={taxOwner}
                                           onChange={({ detail }) => setTaxOwner(detail.value)} />
                                </FormField>
                                <FormField label="Created By">
                                    <Input data-class="input-text" value={initialRegistrationData.createdBy} disabled />
                                </FormField>
                                <FormField label="Created Date Time">
                                    <Input data-class="input-text" value={formatDate(new Date(initialRegistrationData.createDateTime!))} disabled />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setProviderTaxRegConsideration(detail.value)}
                                        value={providerTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Recipient Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setRecipientTaxRegConsideration(detail.value)}
                                        value={recipientTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Name">
                                    <Autosuggest data-class="input-text" data-testid="tax-product-category-name-input"
                                         value={taxProductCategoryName}
                                         options={getTaxProductCategoryNames(categoryNameToIdMapping)}
                                         onFocus={() => getMDMData()}
                                         loadingText='Loading tax product category names'
                                         statusType={loadingMDMData ? 'loading' : (mdmResponseErrorMessage ? 'error' : 'finished')}
                                         errorText={mdmResponseErrorMessage}
                                         onChange={({detail}) => onTaxProductCategoryNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Id">
                                    <Input data-class="input-text" value={taxProductCategoryId}
                                           onChange={({detail}) => setTaxProductCategoryId(detail.value)}/>
                                </FormField>
                                <FormField label="Tax Product Name">
                                    <Autosuggest data-class="input-text"
                                         value={taxProductName}
                                         options={getTaxProductNames(productNameToIdMapping)}
                                         onChange={({detail}) => onTaxProductNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Id">
                                    <Input data-class="input-text" value={taxProductId}
                                           onChange={({detail}) => setTaxProductId(detail.value)}/>
                                </FormField>
                                <FormField label="Export of Services" info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                                    <RadioGroup
                                        onChange={({ detail }) => setExportOfServices(detail.value)}
                                        value={exportOfServices}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                    </div>
                </Form>
            }
        </div>
    );
}