import {Dispatch, SetStateAction} from "react";
import ALEDataService from "src/services/ALEDataService";
import {createLabelValueObject} from "src/utils/commonComponentUtils";

/**
 * Function to get the label-value list of company codes
 * @param companyCodes - company codes name list
 * @returns - companyCodeLabelValueList[]
 */
export const getCompanyCodeLabelValueList = (companyCodes: string[]) => {
    const companyCodeLabelValueList: { label: string; value: string; }[] = [];
    companyCodes.forEach((companyCode) => {
        companyCodeLabelValueList.push(createLabelValueObject(companyCode));
    });
    return companyCodeLabelValueList;
};

/**
 * Function to fetch company codes if not present in react context.
 * @param aleDataService
 * @param setCompanyCodes
 * @param setLoadingCompanyCodes
 * @param setFetchCompanyCodesErrorMessage
 */
export const getCompanyCodesFromALE = async (aleDataService: ALEDataService,
                                     setCompanyCodes: Dispatch<SetStateAction<string[]>>,
                                     setLoadingCompanyCodes: Dispatch<SetStateAction<boolean>>,
                                     setFetchCompanyCodesErrorMessage: Dispatch<SetStateAction<string>>) => {
    setLoadingCompanyCodes(true);
    const {getCompanyCodesResponse, getCompanyCodesError} = await aleDataService.getCompanyCodes();
    if (getCompanyCodesError) {
        setFetchCompanyCodesErrorMessage(getCompanyCodesError);
        return;
    }

    // load company codes into react context
    setCompanyCodes(getCompanyCodesResponse.companyCodes);
    setLoadingCompanyCodes(false);
}