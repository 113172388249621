import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import {RegistrationData} from "src/models/data-registration/RegistrationData";
import {SaveRegistrationDataResponse} from "src/models/data-registration/SaveRegistrationDataResponse";
import {SearchRegistrationDataResponse} from "src/models/data-registration/SearchRegistrationDataResponse";
import {DiscardRegistrationDataResponse} from "src/models/data-registration/DiscardRegistrationDataResponse";

export default class RegistrationDataService {

     async getRegistrationData(registrationNumber: string) {
         let getRegistrationDataResponse = undefined as unknown as RegistrationData;
         let getRegistrationDataError = '';

         try {
             const response = await apiService.getRegistrationData(registrationNumber);
             getRegistrationDataResponse = await response.json() as RegistrationData;
         } catch (ex) {
             getRegistrationDataError = ErrorUtils.getMessage(ex);
         }

         return {
             getRegistrationDataResponse,
             getRegistrationDataError
         }
    }

    async createRegistrationData(registrationData: RegistrationData) {
        let createRegistrationDataResponse = undefined as unknown as SaveRegistrationDataResponse;
        let createRegistrationDataError = '';

        try {
            const response = await apiService.createRegistrationData(registrationData);
            createRegistrationDataResponse = await response.json() as SaveRegistrationDataResponse;
        } catch (ex) {
            createRegistrationDataError = ErrorUtils.getMessage(ex);
        }

        return {
            createRegistrationDataResponse,
            createRegistrationDataError 
        }
    }

    async updateRegistrationData(registrationData: RegistrationData) {
        let updateRegistrationDataResponse = undefined as unknown as SaveRegistrationDataResponse;
        let updateRegistrationDataError = '';

        try {
            const response = await apiService.updateRegistrationData(registrationData);
            updateRegistrationDataResponse = await response.json() as SaveRegistrationDataResponse;
        } catch (ex) {
            updateRegistrationDataError = ErrorUtils.getMessage(ex);
        }

        return {
            updateRegistrationDataResponse,
            updateRegistrationDataError
        }
    }

    async searchRegistrationData(providerCompanyCode: string, recipientCompanyCode: string, pageSize: number, nextToken: string) {
        let searchRegistrationDataResponse = undefined as unknown as SearchRegistrationDataResponse;
        let searchRegistrationDataError = '';

        const payload: any = {
            'providerCompanyCode': providerCompanyCode,
            'recipientCompanyCode': recipientCompanyCode,
            'pageSize': pageSize
        }

        if (nextToken) {
            payload.nextToken = nextToken;
        }

        try {
            const response = await apiService.searchRegistrationData(payload);
            searchRegistrationDataResponse = await response.json() as SearchRegistrationDataResponse;
        } catch (ex) {
            searchRegistrationDataError = ErrorUtils.getMessage(ex);
        }

        return {
            searchRegistrationDataResponse,
            searchRegistrationDataError
        }
    }

    async getPastWorkflowRevisionData(registrationNumber: string, approvalWorkflowRevision: number) {
        let getPastWorkflowRevisionDataResponse = undefined as unknown as RegistrationData;
        let getPastWorkflowRevisionDataError = '';

        const payload: any = {
            'registrationNumber': registrationNumber,
            'approvalWorkflowRevision': approvalWorkflowRevision
        }

        try {
            const response = await apiService.getPastWorkflowRevisionData(payload);
            getPastWorkflowRevisionDataResponse = await response.json() as RegistrationData;
        } catch (ex) {
            getPastWorkflowRevisionDataError = ErrorUtils.getMessage(ex);
        }

        return {
            getPastWorkflowRevisionDataResponse: getPastWorkflowRevisionDataResponse,
            getPastWorkflowRevisionDataError: getPastWorkflowRevisionDataError
        }
    }

    async searchByApprovalStage(approvalWorkflowStage: string, pageSize: number, nextToken: string) {
        let searchByApprovalStageResponse = undefined as unknown as SearchRegistrationDataResponse;
        let searchByApprovalStageError = '';

        const payload: any = {
            'approvalWorkflowStage': approvalWorkflowStage,
            'pageSize': pageSize
        }

        if (nextToken) {
            payload.nextToken = nextToken;
        }

        try {
            const response = await apiService.searchByApprovalStage(payload);
            searchByApprovalStageResponse = await response.json() as SearchRegistrationDataResponse;
        } catch (ex) {
            searchByApprovalStageError = ErrorUtils.getMessage(ex);
        }

        return {
            searchByApprovalStageResponse,
            searchByApprovalStageError
        }
    }

    async discardWorkflowRevision(registrationNumber: string, discardedBy: string, approvalWorkflowRevision: number) {
        let discardWorkflowRevisionResponse = undefined as unknown as DiscardRegistrationDataResponse;
        let discardWorkflowRevisionError = '';

        const payload: any = {
            'registrationNumber': registrationNumber,
            'discardedBy': discardedBy,
            'approvalWorkflowRevision': approvalWorkflowRevision
        }
        try {
            const response = await apiService.discardWorkflowRevision(payload);
            discardWorkflowRevisionResponse = await response.json() as DiscardRegistrationDataResponse;
        } catch (ex) {
            discardWorkflowRevisionError = ErrorUtils.getMessage(ex);
        }

        return {
            discardWorkflowRevisionResponse: discardWorkflowRevisionResponse,
            discardWorkflowRevisionError: discardWorkflowRevisionError
        }
    }
}


