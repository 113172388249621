import './styles/CreateData.scss';
import React, {useContext} from "react";
import {
    Autosuggest,
    Button,
    ColumnLayout,
    DatePicker,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    RadioGroup,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react";
import {RegistrationData} from "src/models/data-registration/RegistrationData";
import ServiceCollection from "src/services/ServiceCollection";
import {useHistory} from "react-router-dom";
import CONSTANTS from "src/utils/constants";
import {customHelpPanel} from "src/components/CustomComponents";
import {getDataFromMDM, getTaxProductCategoryNames, getTaxProductNames} from "src/utils/MDMUtils";
import {GlobalAppContext} from "src/components/App";
import {getCompanyCodeLabelValueList, getCompanyCodesFromALE} from "src/utils/ALEUtils";

export default function CreateData(props: { services: ServiceCollection, userAlias: string }) {
    const {services, userAlias} = props;
    const [loading, setIsLoading] = React.useState(false);
    const history = useHistory();

    // Registration Data fields
    const [atpCalculation, setATPCalculation] = React.useState('false');
    const [currency, setCurrency] = React.useState('');
    const [effectiveFromDate, setEffectiveFromDate] = React.useState('');
    const [effectiveToDate, setEffectiveToDate] = React.useState('');
    const [workbookName, setWorkbookName] = React.useState('');
    const [beat, setBeat] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [providerCompanyCode, setProviderCompanyCode] = React.useState('');
    const [providerEntityName, setProviderEntityName] = React.useState('');
    const [providerJurisdiction, setProviderJurisdiction] = React.useState('');
    const [recipientCompanyCode, setRecipientCompanyCode] = React.useState('');
    const [recipientEntityName, setRecipientEntityName] = React.useState('');
    const [recipientJurisdiction, setRecipientJurisdiction] = React.useState('');
    const [providerTaxRegConsideration, setProviderTaxRegConsideration] = React.useState('false');
    const [recipientTaxRegConsideration, setRecipientTaxRegConsideration] = React.useState('false');
    const [accountingOwner, setAccountingOwner] = React.useState('');
    const [taxOwner, setTaxOwner] = React.useState('');
    const [taxProductCategoryId, setTaxProductCategoryId] = React.useState('');
    const [taxProductCategoryName, setTaxProductCategoryName] = React.useState('');
    const [taxProductId, setTaxProductId] = React.useState('');
    const [taxProductName, setTaxProductName] = React.useState('');
    const [exportOfServices, setExportOfServices] = React.useState('false');
    const [productNameToIdMapping, setProductNameToIdMapping] = React.useState(new Map());
    const [loadingMDMData, setLoadingMDMData] = React.useState(false);
    const [mdmResponseErrorMessage, setMDMResponseErrorMessage] = React.useState('');
    const [loadingCompanyCodes, setLoadingCompanyCodes] = React.useState(false);
    const [fetchCompanyCodesErrorMessage, setFetchCompanyCodesErrorMessage] = React.useState('');

    const {categoryNameToIdMapping, setCategoryNameToIdMapping, categorySubcategoriesMapping,
        setCategorySubcategoriesMapping, companyCodes, setCompanyCodes} = useContext(GlobalAppContext);

    function getMDMData() {
        // If data is already loaded from React context, just return.
        if (categoryNameToIdMapping.size != 0 && categorySubcategoriesMapping.size != 0) {
            return;
        }
        getDataFromMDM(services.mdmDataService, setCategoryNameToIdMapping, setCategorySubcategoriesMapping,
            setLoadingMDMData, setMDMResponseErrorMessage);
    }
    function onTaxProductCategoryNameChange(taxProductCategoryName: string) {
        setTaxProductCategoryName(taxProductCategoryName);
        setTaxProductCategoryId(categoryNameToIdMapping.get(taxProductCategoryName) ?? '');
        setTaxProductName('');
        setTaxProductId('');
        if (categorySubcategoriesMapping.has(taxProductCategoryName)) {
            setProductNameToIdMapping(new Map(categorySubcategoriesMapping.get(taxProductCategoryName)!
                .map(taxProductSubcategory => [taxProductSubcategory.taxProductName, taxProductSubcategory.taxProductId])));
        } else {
            setProductNameToIdMapping(new Map<string, string>());
        }
    }

    function onTaxProductNameChange(taxProductName: string) {
        setTaxProductName(taxProductName);
        setTaxProductId(productNameToIdMapping.get(taxProductName) ?? '');
    }

    function getCompanyCodes() {
        // If company codes are already loaded from React context, just return.
        if (companyCodes.length != 0) {
            return;
        }
        getCompanyCodesFromALE(services.aleDataService, setCompanyCodes, setLoadingCompanyCodes, setFetchCompanyCodesErrorMessage);
    }

    function onProviderCompanyCodeChange(companyCode: string) {
        setProviderCompanyCode(companyCode);
        setProviderEntityName('');
        setProviderJurisdiction('');
    }

    function onRecipientCompanyCodeChange(companyCode: string) {
        setRecipientCompanyCode(companyCode);
        setRecipientEntityName('');
        setRecipientJurisdiction('');
    }

    async function onCompanyCodeSelect(companyCode: string) {
        const {getALEDataResponse, getALEDataError} = await services.aleDataService.getALEData(companyCode);
        if (getALEDataError) {
            services.messageService.showErrorAutoDismissBanner(getALEDataError, CONSTANTS.CREATE_DATA_PAGE_ERROR_DISPLAY_TIME);
            return undefined;
        }
        return getALEDataResponse;
    }
    async function onProviderCompanyCodeSelect(companyCode: string) {
        setProviderCompanyCode(companyCode);
        const getALEDataResponse = await onCompanyCodeSelect(companyCode);
        if (getALEDataResponse !== undefined) {
            setProviderEntityName(getALEDataResponse.entityName);
            // Jurisdiction from Backend comes as `USTX000 [United States/Texas]`, we are only interested in first 2 chars
            setProviderJurisdiction(getALEDataResponse.jurisdiction.substring(0, 2))
        }
    }

    async function onRecipientCompanyCodeSelect(companyCode: string) {
        setRecipientCompanyCode(companyCode);
        const getALEDataResponse = await onCompanyCodeSelect(companyCode);
        if (getALEDataResponse !== undefined) {
            setRecipientEntityName(getALEDataResponse.entityName);
            // Jurisdiction from Backend comes as `USTX000 [United States/Texas]`, we are only interested in first 2 chars
            setRecipientJurisdiction(getALEDataResponse.jurisdiction.substring(0, 2))
        }
    }

    async function createRegistrationData() {
        const registrationData = setUpRegistrationData();

        setIsLoading(true);
        const {createRegistrationDataResponse, createRegistrationDataError} =
            await services.registrationDataService.createRegistrationData(registrationData);
        setIsLoading(false);

        if (createRegistrationDataError) {
            services.messageService.showErrorAutoDismissBanner(
                createRegistrationDataError, CONSTANTS.CREATE_DATA_PAGE_ERROR_DISPLAY_TIME);
            return;
        }

        // Set registrationNumber, createDateTime and lastUpdatedDateTime generated in backend
        registrationData.registrationNumber = createRegistrationDataResponse.registrationNumber;
        registrationData.createDateTime = createRegistrationDataResponse.createDateTime!;
        registrationData.lastUpdatedDateTime = createRegistrationDataResponse.lastUpdatedDateTime;
        registrationData.approvalWorkflowStage = CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT;
        registrationData.approvalWorkflowRevision = 1;
        registrationData.approvalWorkflowRevisionCount = 1;

        history.push({
            pathname: '/show',
            state: registrationData
        });
    }

    function setUpRegistrationData() {
        const registrationData: RegistrationData = {
            atpCalculation: JSON.parse(atpCalculation),
            providerCompanyCode: providerCompanyCode,
            recipientCompanyCode: recipientCompanyCode,
            providerTaxRegConsideration: JSON.parse(providerTaxRegConsideration),
            recipientTaxRegConsideration: JSON.parse(recipientTaxRegConsideration),
            exportOfServices: JSON.parse(exportOfServices),
            createdBy: userAlias,
            lastUpdatedBy: userAlias
        };

        if (currency) {
            registrationData.currency = currency;
        }

        if (effectiveFromDate) {
            registrationData.effectiveFromDate = effectiveFromDate;
        }

        if (effectiveToDate) {
            registrationData.effectiveToDate = effectiveToDate;
        }

        if (workbookName) {
            registrationData.workbookName = workbookName;
        }

        if (beat) {
            registrationData.beat = beat;
        }

        if (description) {
            registrationData.description = description;
        }

        if (comments) {
            registrationData.comments = comments;
        }

        if (providerEntityName) {
            registrationData.providerEntityName = providerEntityName;
        }

        if (providerJurisdiction) {
            registrationData.providerJurisdiction = providerJurisdiction;
        }

        if (recipientEntityName) {
            registrationData.recipientEntityName = recipientEntityName;
        }

        if (recipientJurisdiction) {
            registrationData.recipientJurisdiction = recipientJurisdiction;
        }

        if (accountingOwner) {
            registrationData.accountingOwner = accountingOwner;
        }

        if (taxOwner) {
            registrationData.taxOwner = taxOwner;
        }

        if (taxProductName) {
            registrationData.taxProductName = taxProductName;
        }

        if (taxProductId) {
            registrationData.taxProductId = taxProductId;
        }

        if (taxProductCategoryName) {
            registrationData.taxProductCategoryName = taxProductCategoryName;
        }

        if (taxProductCategoryId) {
            registrationData.taxProductCategoryId = taxProductCategoryId;
        }

        return registrationData;
    }

    return (
        <div>
            {loading ?
                <StatusIndicator type="loading"> Loading page </StatusIndicator> :
                <Form
                    actions={
                        <Button data-testid="save-registration-data-button"
                                disabled={recipientCompanyCode.trim().length == 0 || providerCompanyCode.trim().length == 0}
                                variant="primary"
                                onClick={createRegistrationData}>
                            Save
                        </Button>
                    }
                    header={<Header variant="h1">Create Registration Data</Header>}
                >
                    <div className="create-registration-data" >
                        <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="ATP Calculation">
                                    <RadioGroup
                                        onChange={({ detail }) => setATPCalculation(detail.value)}
                                        value={atpCalculation}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Currency">
                                    <Input data-class="input-text" data-testid="currency-input" value={currency}
                                           onChange={({ detail }) => setCurrency(detail.value)} />
                                </FormField>
                                <FormField label="Effective From Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        data-testid="effective-from-date-input"
                                        value={effectiveFromDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveFromDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Effective To Date" constraintText="Use YYYY/MM/DD format.">
                                    <DatePicker
                                        data-testid="effective-to-date-input"
                                        value={effectiveToDate}
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        onChange={({ detail }) => setEffectiveToDate(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                                    <Input data-class="input-text" data-testid="workbook-name-input" value={workbookName}
                                           onChange={({ detail }) => setWorkbookName(detail.value)} />
                                </FormField>
                                <FormField label="Beat" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                                    <Input data-class="input-text" data-testid="beat-input" value={beat}
                                           onChange={({ detail }) => setBeat(detail.value)} />
                                </FormField>
                                <FormField label="Description">
                                    <Textarea data-class="input-text" data-testid="description-input" value={description}
                                           onChange={({ detail }) => setDescription(detail.value)} />
                                </FormField>
                                <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                                    <Textarea data-class="input-text" data-testid="comments-input" value={comments}
                                           onChange={({ detail }) => setComments(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Party Information" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}
                                           constraintText={providerCompanyCode.trim().length == 0 ? 'required': ''}>
                                    <Autosuggest data-class="input-text" data-testid="provider-company-code-input"
                                         value={providerCompanyCode}
                                         options={getCompanyCodeLabelValueList(companyCodes)}
                                         onFocus={() => getCompanyCodes()}
                                         loadingText='Loading company codes'
                                         statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                         errorText={fetchCompanyCodesErrorMessage}
                                         onChange={({detail}) => onProviderCompanyCodeChange(detail.value)}
                                         onSelect={({detail}) => onProviderCompanyCodeSelect(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         virtualScroll
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Recipient Company Code*" info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}
                                           constraintText={recipientCompanyCode.trim().length == 0 ? 'required': ''}>
                                    <Autosuggest data-class="input-text" data-testid="recipient-company-code-input"
                                         value={recipientCompanyCode}
                                         options={getCompanyCodeLabelValueList(companyCodes)}
                                         onFocus={() => getCompanyCodes()}
                                         loadingText='Loading company codes'
                                         statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                         errorText={fetchCompanyCodesErrorMessage}
                                         onChange={({detail}) => onRecipientCompanyCodeChange(detail.value)}
                                         onSelect={({detail}) => onRecipientCompanyCodeSelect(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         virtualScroll
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Provider Entity Name">
                                    <Input data-class="input-text" data-testid="provider-entity-name-input" value={providerEntityName}
                                           onChange={({ detail }) => setProviderEntityName(detail.value)} />
                                </FormField>
                                <FormField label="Recipient Entity Name">
                                    <Input data-class="input-text" data-testid="recipient-entity-name-input" value={recipientEntityName}
                                           onChange={({ detail }) => setRecipientEntityName(detail.value)} />
                                </FormField>
                                <FormField label="Provider Jurisdiction">
                                    <Input data-class="input-text" data-testid="provider-jurisdiction-input" value={providerJurisdiction}
                                           onChange={({ detail }) => setProviderJurisdiction(detail.value)} />
                                </FormField>
                                <FormField label="Recipient Jurisdiction">
                                    <Input data-class="input-text" data-testid="recipient-jurisdiction-input" value={recipientJurisdiction}
                                           onChange={({ detail }) => setRecipientJurisdiction(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Registration Data Contributors" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Accounting Owner">
                                    <Input data-class="input-text" data-testid="accounting-owner-input" value={accountingOwner}
                                           onChange={({ detail }) => setAccountingOwner(detail.value)} />
                                </FormField>
                                <FormField label="Tax Owner">
                                    <Input data-class="input-text" data-testid="tax-owner-input" value={taxOwner}
                                           onChange={({ detail }) => setTaxOwner(detail.value)} />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                        <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                            <ColumnLayout columns={2}>
                                <FormField label="Provider Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setProviderTaxRegConsideration(detail.value)}
                                        value={providerTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Recipient Tax Reg Consideration">
                                    <RadioGroup
                                        onChange={({ detail }) => setRecipientTaxRegConsideration(detail.value)}
                                        value={recipientTaxRegConsideration}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Name">
                                    <Autosuggest data-class="input-text" data-testid="tax-product-category-name-input"
                                         value={taxProductCategoryName}
                                         options={getTaxProductCategoryNames(categoryNameToIdMapping)}
                                         onFocus={() => getMDMData()}
                                         loadingText='Loading tax product category names'
                                         statusType={loadingMDMData ? 'loading' : (mdmResponseErrorMessage ? 'error' : 'finished')}
                                         errorText={mdmResponseErrorMessage}
                                         onChange={({detail}) => onTaxProductCategoryNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Category Id">
                                    <Input data-class="input-text" data-testid="tax-product-category-id-input"
                                           value={taxProductCategoryId}
                                           onChange={({detail}) => setTaxProductCategoryId(detail.value)}/>
                                </FormField>
                                <FormField label="Tax Product Name">
                                    <Autosuggest data-class="input-text" data-testid="tax-product-name-input"
                                         value={taxProductName}
                                         options={getTaxProductNames(productNameToIdMapping)}
                                         onChange={({detail}) => onTaxProductNameChange(detail.value)}
                                         enteredTextLabel={value => `Use: "${value}"`}
                                         empty="No matches found"
                                    />
                                </FormField>
                                <FormField label="Tax Product Id">
                                    <Input data-class="input-text" data-testid="tax-product-id-input"
                                           value={taxProductId}
                                           onChange={({detail}) => setTaxProductId(detail.value)}/>
                                </FormField>
                                <FormField label="Export of Services"
                                           info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                                    <RadioGroup
                                        onChange={({ detail }) => setExportOfServices(detail.value)}
                                        value={exportOfServices}
                                        items={[
                                            { value: "true", label: "True" },
                                            { value: "false", label: "False" },
                                        ]}
                                    />
                                </FormField>
                            </ColumnLayout>
                        </ExpandableSection>
                    </div>
                </Form>
            }
        </div>
    );
}