import React from "react";

import {RegistrationData} from "src/models/data-registration/RegistrationData"; 
import CONSTANTS from "src/utils/constants";
import {Steps} from 'antd';

export default function ApprovalWorkflow(registrationData : RegistrationData) {

    interface StepItem  {
        title: string,
        description: string,
        status: 'process' | 'finish' | 'wait' | 'error' | undefined,
        disabled: boolean
    }

    let currentStage: number;

    let stages = CONSTANTS.APPROVAL_WORKFLOW_STAGES;

    const stepItems: StepItem[] = [
        {title: stages.DRAFT, description: '', status: undefined,disabled: true},
        {title: stages.IN_ACCOUNTING_APPROVAL, description: '',status: undefined, disabled: true}, 
        {title: stages.IN_TAX_APPROVAL, description: '', status: undefined,disabled: true}, 
        {title: stages.ACTIVE, description: '', status: undefined, disabled: true}, 
        {title: stages.INACTIVE, description: '', status: undefined, disabled: true}, 
        {title: stages.CANCELLED, description: '',status: undefined, disabled: true}
    ];

    switch(registrationData.approvalWorkflowStage) {
        case stages.DRAFT: {
            currentStage = 0;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            break;
        }
        case stages.IN_ACCOUNTING_APPROVAL: {
            currentStage = 1;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            if(registrationData.taxApprover) {
                stepItems[2].description = 'Approved By: ' + registrationData.taxApprover;
                stepItems[2].status = 'finish';
            }
            break;
        }
        case stages.IN_TAX_APPROVAL: {
            currentStage = 2;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            stepItems[1].description = 'Approved By: ' + registrationData.accountingApprover!;
            break;
        }
        case stages.ACTIVE: {
            currentStage = 3;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            stepItems[1].description = 'Approved By: ' + registrationData.accountingApprover!;
            stepItems[2].description = 'Approved By: ' + registrationData.taxApprover!;
            if(registrationData.reactivatedBy) {
                stepItems[3].description = 'Reactivated By: ' + registrationData.reactivatedBy;
            }
            break;
        }
        case stages.INACTIVE: {
            currentStage = 4;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            stepItems[1].description = 'Approved By: ' + registrationData.accountingApprover!
            stepItems[2].description = 'Approved By: ' + registrationData.taxApprover!;
            stepItems[4].description = 'Deactivated By: ' + registrationData.deactivatedBy!;
            stepItems[3].status = 'wait';
            break;
        }
        case stages.CANCELLED: {
            currentStage = 5;
            stepItems[0].description = 'Updated By: ' + registrationData.lastUpdatedBy;
            stepItems[1].description = registrationData.accountingApprover ? 'Approved By: ' + registrationData.accountingApprover! : '';
            stepItems[2].description = registrationData.taxApprover ? 'Approved By: ' + registrationData.taxApprover! : '';
            stepItems[5].description = 'By: ' + registrationData.cancelledBy!;
            stepItems[3].status = 'wait';
            stepItems[4].status = 'wait';
            stepItems[1].status = registrationData.accountingApprover ? 'finish' : 'wait';
            stepItems[2].status = registrationData.taxApprover ? 'finish' : 'wait';
            break;
        }
        default: {
            break;
        }
    }

    return <Steps current={currentStage!} direction='horizontal' labelPlacement='horizontal' type='default' items={stepItems} size='default'></Steps>;
}

