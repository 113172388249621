import {Dispatch, SetStateAction} from "react";
import MDMDataService from "src/services/MDMDataService";
import {Subcategory} from "src/models/GetMDMDataResponse";
import {createLabelValueObject} from "src/utils/commonComponentUtils";

/**
 * Function to get the list of tax product category names
 * @param categoryNameToIdMapping - category name to id mapping
 * @returns - category[]
 */
export const getTaxProductCategoryNames = (categoryNameToIdMapping: Map<string, string>) => {
    const taxProductCategoryNames = [];
    for (const key of categoryNameToIdMapping.keys()) {
        taxProductCategoryNames.push(createLabelValueObject(key));
    }
    return taxProductCategoryNames;
};


/**
 * Function to get the list of tax product names
 * @param productNameToIdMapping - product name to id mapping
 * @returns - taxProductNames[]
 */
export const getTaxProductNames = (productNameToIdMapping: Map<string, string>) => {
    const taxProductNames = [];
    for (const key of productNameToIdMapping.keys()) {
        taxProductNames.push(createLabelValueObject(key));
    }
    return taxProductNames;
};


/**
 * Function to fetch MDM data if not present in react context.
 * @param mdmDataService
 * @param setLoadingMDMData
 * @param setMDMResponseErrorMessage
 */
export const getDataFromMDM = async (mdmDataService: MDMDataService,
                                     setCategoryNameToIdMapping: Dispatch<SetStateAction<Map<string, string>>>,
                                     setCategorySubcategoriesMapping: Dispatch<SetStateAction<Map<string, Subcategory[]>>>,
                                     setLoadingMDMData: Dispatch<SetStateAction<boolean>>,
                                     setMDMResponseErrorMessage: Dispatch<SetStateAction<string>>) => {
    setLoadingMDMData(true);
    const {getMDMDataResponse, getMDMDataError} = await mdmDataService.getMDMData();
    if (getMDMDataError) {
        setMDMResponseErrorMessage(getMDMDataError);
        return;
    }

    // load latest MDM data into react context
    setCategoryNameToIdMapping(new Map(Object.entries(getMDMDataResponse.categoryNameToIdMapping)));
    setCategorySubcategoriesMapping(new Map(Object.entries(getMDMDataResponse.categorySubcategoriesMapping)));
    setLoadingMDMData(false);
}