import CONSTANTS from '../utils/constants';
import {ensureAuthenticated} from "src/utils/auth/authenticate";
import {RegistrationData} from "src/models/data-registration/RegistrationData";

// Common abstraction for calling any API. The parameters used for querying
// the API are all common among the API endpoints, so this method abstracts
// this functionality to make the API methods more readable.
async function _callExternalApi(method: string, url: string, body = null, headers = {}) {
    const auth = await ensureAuthenticated() as any;
    const session = auth.getSignInUserSession();
    const jwtToken = session.getIdToken().getJwtToken();

    // Utility function to check response status and handle errors based on status code
    async function checkStatus(response: any) {
        if (response.status >= 200 && response.status < 300) {
            // If everything looks good
            return;
        } else if (response.status >= 300 && response.status <= 500) {
            const responseBody = await response.json();
            throw new Error(responseBody.message);
        } else {
            // All other status codes (mostly 500s will display this generic message)
            throw new Error(CONSTANTS.GENERIC_ERROR_MESSAGE);
        }
    }

    const response = await fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            ...headers,
            Authorization: jwtToken,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: method === 'GET' ? null : JSON.stringify(body),
    });

    await checkStatus(response);
    return response;
}

async function _callBusinessApi(method: string, endpoint: string, body: any | undefined = null, headers = {}) {
    const baseUrl = CONSTANTS.ENVIRONMENT_VARIABLES.ICRS_API_GATEWAY_ARN as string;
    const url = `${baseUrl}${endpoint}`;

    return await _callExternalApi(method, url, body, headers);
}

const API = {
    async getRegistrationData(registrationNumber: string) {
        return await _callBusinessApi('GET', `/registrationData/${registrationNumber}/latest`);
    },

    async createRegistrationData(registrationData: RegistrationData) {
        return await _callBusinessApi('POST', '/create/registrationData', registrationData);
    },

    async updateRegistrationData(registrationData: RegistrationData) {
        return await _callBusinessApi('POST', '/update/registrationData', registrationData);
    },

    async searchRegistrationData(payload: any) {
        return await _callBusinessApi('POST', '/search/registrationData', payload);
    },

    async getPastWorkflowRevisionData(payload: any) {
        return await _callBusinessApi('POST', '/workflowRevisionData/past', payload);
    },

    async searchByApprovalStage(payload: any) {
        return await _callBusinessApi('POST', '/search/registrationData/approvalStage', payload);
    },

    async discardWorkflowRevision(payload: any) {
        return await _callBusinessApi('POST', '/workflowRevision/discard', payload);
    },

    async getMDMData() {
        return await _callBusinessApi('GET', '/tax-products/categories');
    },

    async getCompanyCodes() {
        return await _callBusinessApi('GET', '/aleData/companyCodes');
    },

    async getALEData(companyCode: string) {
        return await _callBusinessApi('GET', `/aleData/companyCode/${companyCode}`);
    },
}

export default API;