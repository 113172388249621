/**
 * Function to create Object of Label and Value
 *
 * @param value value in the object
 * @param label label label in the object
 * @returns object
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};
