import React from 'react';
import { nanoid } from 'nanoid'
import ICRSAction from "src/models/ICRSAction";

export default class MessageService {

    bannerDispatcher?: React.Dispatch<ICRSAction>;
    addBannerMessageAction?: ICRSAction;
    clearBannerMessageAction?: ICRSAction;

    public setupForBanner(dispatcher: React.Dispatch<ICRSAction>, addMessageAction: ICRSAction, clearMessageAction: ICRSAction) {
        this.addBannerMessageAction = addMessageAction;
        this.clearBannerMessageAction = clearMessageAction;
        this.bannerDispatcher = dispatcher;
    }

    public showBannerMessage(message: string, type: 'success' | 'warning' | 'info' | 'error'){
        if (this.bannerDispatcher == null || this.addBannerMessageAction == null || this.clearBannerMessageAction == null) {
            console.error("Dispatcher and addMessageAction has not been set. Invoke setupForBanner method before invoking show method.");
            return;
        }
        const id = nanoid();
        const $bannerDispatcher = this.bannerDispatcher;
        const $clearAction = this.clearBannerMessageAction;
        const dismissAction = () => $bannerDispatcher($clearAction.withPayload(id));
        this.bannerDispatcher(this.addBannerMessageAction.withPayload({
            id: id,
            content: message,
            type: type,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: dismissAction
        }));
        return id;
    }


    /**
     * Allows showing an error message in top banner
     * @param message The message
     */
    public showErrorBanner(message: string) {
        return this.showBannerMessage(message,"error")
    }

    /**
     * Allows showing an error banner message for a period of time
     * @param message The message
     * @param duration The duration in milliseconds (default is 3000)
     */
    public showErrorAutoDismissBanner(message: string, duration: number = 3000) {
        const messageId = this.showErrorBanner(message);
        setTimeout((dispatch: React.Dispatch<ICRSAction>, action: ICRSAction) => {
            dispatch(action.withPayload(messageId));
        }, duration, this.bannerDispatcher, this.clearBannerMessageAction);
    }
}