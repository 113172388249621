import React, {useState} from "react";
import {
    Button,
    ColumnLayout,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
    Modal,
    Box,
    Select,
    Grid
} from "@amzn/awsui-components-react";
import {RegistrationData} from "src/models/data-registration/RegistrationData";
import {useHistory, useLocation} from "react-router-dom";
import {GlobalAppContext} from "src/components/App";
import {formatDate} from "src/utils/DateUtils";
import {customHelpPanel} from "src/components/CustomComponents";
import CONSTANTS from "src/utils/constants";
import './styles/ShowData.scss';
import ApprovalWorkflow from 'src/components/ApprovalWorkflow';
import {DiscardRegistrationDataResponse} from "src/models/data-registration/DiscardRegistrationDataResponse";


export default function ShowData(props : any) {
    const {services, userAlias, isAccountingUser, isTaxUser} = props;
    const history = useHistory();
    const location = useLocation<any>();
    const registrationData = location.state as RegistrationData;
    const {isViewer} = React.useContext(GlobalAppContext);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [activateModalVisible, setActivateModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [discardModalVisible, setDiscardModalVisible] = useState(false);

    const DRAFT = CONSTANTS.APPROVAL_WORKFLOW_STAGES.DRAFT;
    const IN_ACCOUNTING_APPROVAL = CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_ACCOUNTING_APPROVAL;
    const IN_TAX_APPROVAL = CONSTANTS.APPROVAL_WORKFLOW_STAGES.IN_TAX_APPROVAL;
    const ACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.ACTIVE;
    const INACTIVE = CONSTANTS.APPROVAL_WORKFLOW_STAGES.INACTIVE;
    const CANCELLED = CONSTANTS.APPROVAL_WORKFLOW_STAGES.CANCELLED;
    
    interface SelectedOption {
        label: string,
        value: string
    }

    function goToEditPage() {
        history.push({
            pathname: '/edit',
            state: registrationData
        });
    }

    function createRegistrationDataPayload(approvalWorkflowStage: string, accountingApprover: string, taxApprover: string,
        cancelledBy: string, deactivatedBy: string, reactivatedBy: string) {
        return {
            registrationNumber: registrationData.registrationNumber,
            approvalWorkflowStage: approvalWorkflowStage,
            atpCalculation: registrationData.atpCalculation,
            currency: registrationData.currency,
            effectiveFromDate: registrationData.effectiveFromDate,
            effectiveToDate: registrationData.effectiveToDate,
            workbookName: registrationData.workbookName,
            beat: registrationData.beat,
            description: registrationData.description,
            comments: registrationData.comments,
            providerCompanyCode: registrationData.providerCompanyCode,
            providerEntityName: registrationData.providerEntityName,
            providerJurisdiction: registrationData.providerJurisdiction,
            recipientCompanyCode: registrationData.recipientCompanyCode,
            recipientEntityName: registrationData.recipientEntityName,
            recipientJurisdiction: registrationData.recipientJurisdiction,
            providerTaxRegConsideration: registrationData.providerTaxRegConsideration,
            recipientTaxRegConsideration: registrationData.recipientTaxRegConsideration,
            accountingOwner: registrationData.accountingOwner,
            taxOwner: registrationData.taxOwner,
            taxProductId: registrationData.taxProductId,
            taxProductName: registrationData.taxProductName,
            taxProductCategoryId: registrationData.taxProductCategoryId,
            taxProductCategoryName: registrationData.taxProductCategoryName,
            exportOfServices: registrationData.exportOfServices,
            accountingApprover: accountingApprover ? accountingApprover : registrationData.accountingApprover,
            taxApprover: taxApprover ? taxApprover : registrationData.taxApprover,
            cancelledBy: cancelledBy ? cancelledBy : registrationData.cancelledBy,
            deactivatedBy: deactivatedBy ? deactivatedBy : registrationData.deactivatedBy,
            reactivatedBy: reactivatedBy ? reactivatedBy : registrationData.reactivatedBy,
            createdBy: registrationData.createdBy,
            lastUpdatedBy: registrationData.lastUpdatedBy,
            createDateTime: registrationData.createDateTime,
            lastUpdatedDateTime: registrationData.lastUpdatedDateTime
        }                                       
    }

     function submitForApproval() {
        const payload = createRegistrationDataPayload(IN_ACCOUNTING_APPROVAL, "", "", "", "", "");
        updateRegistrationData(payload);

    }

     function accountingApprove() {
        const payload = createRegistrationDataPayload(registrationData!.taxApprover ? ACTIVE : IN_TAX_APPROVAL, userAlias, "", "", "", "");
        updateRegistrationData(payload);

    }

    function taxApprove() {
        const payload = createRegistrationDataPayload(registrationData!.accountingApprover ? ACTIVE : IN_ACCOUNTING_APPROVAL, "", userAlias, "", "", "");
        updateRegistrationData(payload);
    }

    function deactivate() {
       const payload = createRegistrationDataPayload(INACTIVE, "", "", "", userAlias, "");
       updateRegistrationData(payload);
   }

    function activate() {
        setActivateModalVisible(false);
        const payload = createRegistrationDataPayload(ACTIVE, "", "", "", "", userAlias);
        updateRegistrationData(payload);
    }

    function cancel() {
        setCancelModalVisible(false);
        const payload = createRegistrationDataPayload(CANCELLED, "", "", userAlias, "", "");
        updateRegistrationData(payload);
    }

    async function updateRegistrationData(payload : RegistrationData) {
        const {updateRegistrationDataResponse, updateRegistrationDataError} =
        await services.registrationDataService.updateRegistrationData(payload);
    
        if (updateRegistrationDataError) {
            services.messageService.showErrorAutoDismissBanner(updateRegistrationDataError);
            return;
        }

        payload.approvalWorkflowRevision = updateRegistrationDataResponse.approvalWorkflowRevision;
        payload.approvalWorkflowRevisionCount = updateRegistrationDataResponse.approvalWorkflowRevision;
        payload.lastUpdatedDateTime = updateRegistrationDataResponse.lastUpdatedDateTime;

        history.push({
            pathname: '/show',
            state: payload
        });
    }

    async function getPastWorkflowRevisionData(selectedOption : SelectedOption, approvalWorkflowRevisionCount: number) {
        const {getPastWorkflowRevisionDataResponse, getPastWorkflowRevisionDataError} =
          await services.registrationDataService.getPastWorkflowRevisionData(registrationData.registrationNumber!, Number(selectedOption.value));
    
        if (getPastWorkflowRevisionDataError) {
            services.messageService.showErrorAutoDismissBanner(getPastWorkflowRevisionDataError);
            return;
        }

        setSelectedOption(selectedOption as SelectedOption); 
        setDiscardModalVisible(false);

        // Set current revision count.
        getPastWorkflowRevisionDataResponse.approvalWorkflowRevisionCount = approvalWorkflowRevisionCount;
    
        history.push({
            pathname: '/show',
            state: getPastWorkflowRevisionDataResponse as RegistrationData
        });
    }
    
    async function discardWorkflowRevision() {
        const {discardWorkflowRevisionResponse, discardWorkflowRevisionError} =
            await services.registrationDataService.discardWorkflowRevision(
              registrationData.registrationNumber!, userAlias, registrationData.approvalWorkflowRevision!);
    
        if (discardWorkflowRevisionError) {
            services.messageService.showErrorAutoDismissBanner(discardWorkflowRevisionError);
            return;
        }

        // If the first revision is discarded, revert to home page.
        if (registrationData.approvalWorkflowRevision == 1) {
            history.push({
                pathname: '/'
            });
            return;
        }

        const response = discardWorkflowRevisionResponse as DiscardRegistrationDataResponse;
        // The total approval workflow revisions are equal to the current revision.
        response.approvalWorkflowRevisionCount = response.approvalWorkflowRevision;

        setSelectedOption({label: 'Revision ' + response.approvalWorkflowRevision! + ' (Current)', value: response.approvalWorkflowRevision!.toString()});
            setDiscardModalVisible(false);
    
            history.push({
                pathname: '/show',
                state: response
            });
    }

    // When show page URL is directly posted in browser, registration data will be undefined.
    if (registrationData === undefined) {
        history.push({
            pathname: '/'
        });
        return <></>;
    }

    let pastRecordOptions: any = [];
    let correctOption = {label: '', value: ''};

    let approvalWorkflowRevisions = registrationData.approvalWorkflowRevisionCount!;
    for (let approvalWorkflowRevision = approvalWorkflowRevisions; approvalWorkflowRevision > 0; approvalWorkflowRevision--) {
        let label = "Revision " + approvalWorkflowRevision;
        if (approvalWorkflowRevision === approvalWorkflowRevisions) {
            label += " (Current)";
        }

        pastRecordOptions.push({label: label, value: approvalWorkflowRevision.toString()});

        if (approvalWorkflowRevision === registrationData.approvalWorkflowRevision!) {
            correctOption = {label: label, value: approvalWorkflowRevision.toString()};
        }
    }

    const [selectedOption, setSelectedOption] = useState(correctOption);

    let stage = registrationData.approvalWorkflowStage;

    return (
        <div>
            {registrationData !== undefined ?
              <div>
                <Modal onDismiss={() => setDiscardModalVisible(false)} visible={discardModalVisible} 
                    footer={
                        <Box float="right">
                            <SpaceBetween direction ="horizontal" size="xs">
                                <Button variant="primary" onClick={discardWorkflowRevision} data-testid="discard-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setDiscardModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Discard Current Revision" data-testid="discard-modal"
                >
                    Discarding this current revision will result in revert back to previous approved revision if present.
                    This action cannot be undone. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setCancelModalVisible(false)} visible={cancelModalVisible} 
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={cancel} data-testid="cancel-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setCancelModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Cancel Registration Record" data-testid="cancel-modal"
                >
                    Cancelling this record cannot be undone. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setActivateModalVisible(false)} visible={activateModalVisible} 
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={activate} data-testid="activate-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setActivateModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Activate Registration Record" data-testid="activate-modal"
                >
                    Activating this record will move it back into the Active stage. Are you sure you want to proceed?
                </Modal>
                <Modal onDismiss={() => setEditModalVisible(false)} visible={editModalVisible} 
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={() => {setEditModalVisible(false);goToEditPage()}} data-testid="edit-modal-yes-button">Yes</Button>
                                <Button variant="link" onClick={() => setEditModalVisible(false)}>No</Button>
                            </SpaceBetween>
                        </Box>
                            }
                    header="Edit Registration Record" data-testid="edit-modal"
                    >
                    Editing this record will move it back to draft. Are you sure you want to do proceed?
                </Modal>
                <SpaceBetween size="xl">
                    <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                        <div></div>
                        <FormField label="Select Past Revision">
                            <Select data-testid="select-past-data" selectedOption={selectedOption} options={pastRecordOptions} onChange={({ detail: {selectedOption} }) => { 
                                if (Number(selectedOption.value) !== registrationData.approvalWorkflowRevision) {
                                    getPastWorkflowRevisionData(selectedOption as SelectedOption, registrationData.approvalWorkflowRevisionCount!);
                                }
                                }}></Select>
                        </FormField>
                    </Grid>
                    <ApprovalWorkflow {...registrationData}></ApprovalWorkflow>
                    <Form
                        header={
                            <Grid gridDefinition={[{colspan:4}, {colspan: 8}]}>
                                <Header variant="h1">Registration Data</Header>
                                <div data-class="actions">
                                    { isViewer || (Number(selectedOption!.value) !== registrationData.approvalWorkflowRevisionCount)
                                     ? <></> : 
                                    <SpaceBetween direction="horizontal" size="xs">  
                                        {stage !== INACTIVE && stage !== CANCELLED ? <Button data-testid="go-to-edit-page-button" variant="normal" onClick={() => {
                                            if (stage === ACTIVE) {
                                                setEditModalVisible(true);
                                            } else {
                                                goToEditPage();
                                            }
                                        }}>Edit</Button> : <></>}
                                        {(stage === DRAFT || stage === IN_ACCOUNTING_APPROVAL || stage === IN_TAX_APPROVAL)?
                                            <Button data-testid="discard-revision-button" variant="primary" onClick={() => setDiscardModalVisible(true)}>Discard Version</Button> : <></>}
                                        {stage === DRAFT ? <Button data-testid="submit-for-approval-button" variant="primary" onClick={submitForApproval}>Submit for Approval</Button> : <></>}
                                        {stage === IN_ACCOUNTING_APPROVAL && isAccountingUser && (userAlias !== registrationData.lastUpdatedBy) ? 
                                            <Button data-testid="accounting-approve-button" variant="primary" onClick={accountingApprove}>Approve as Accounting</Button> : <></>}
                                        {((stage === IN_ACCOUNTING_APPROVAL && !registrationData.taxApprover) || stage === IN_TAX_APPROVAL) && isTaxUser && (userAlias !== registrationData.lastUpdatedBy) ?
                                             <Button data-testid="tax-approve-button" variant="primary" onClick={taxApprove}>Approve as Tax</Button> : <></>}
                                        {stage === ACTIVE ? <Button data-testid="deactivate-button" variant="primary" onClick={deactivate}>Deactivate</Button> : <></>}
                                        {stage === INACTIVE ? <Button data-testid="activate-button" variant="primary" onClick={() =>  setActivateModalVisible(true)}>Reactivate</Button> : <></>}
                                        {(stage === ACTIVE || stage === INACTIVE) ? <Button data-testid="cancel-button" variant="primary" onClick={() => setCancelModalVisible(true)}>Cancel Registration Record</Button> : <></>}
                                    </SpaceBetween> 
                                    }
                                </div>
                            </Grid>      
                            }
                    >
                        <div className="display-registration-data" style={{ backgroundColor: 'whitesmoke' }}>
                            <ExpandableSection headerText="Registration Details" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Registration Number">
                                        <Input value={registrationData.registrationNumber!} disabled />
                                    </FormField>
                                    <FormField label="ATP Calculation">
                                        <Input value={String(registrationData.atpCalculation)} disabled />
                                    </FormField>
                                    <FormField label="Effective From Date">
                                        <Input value={registrationData.effectiveFromDate ?
                                            formatDate(new Date(registrationData.effectiveFromDate)) : ''} disabled />
                                    </FormField>
                                    <FormField label="Effective To Date">
                                        <Input value={registrationData.effectiveToDate ?
                                            formatDate(new Date(registrationData.effectiveToDate)) : ''} disabled />
                                    </FormField>
                                    <FormField label="Workbook Name" info={customHelpPanel(CONSTANTS.FIELDS_INFO.WORKBOOK_NAME)}>
                                        <Input value={registrationData.workbookName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Beat" info={customHelpPanel(CONSTANTS.FIELDS_INFO.BEAT)}>
                                        <Input value={registrationData.beat ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Description">
                                        <Textarea value={registrationData.description ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Comments" info={customHelpPanel(CONSTANTS.FIELDS_INFO.COMMENTS)}>
                                        <Textarea value={registrationData.comments ?? ''} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Party Information" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Provider Company Code" info={customHelpPanel(CONSTANTS.FIELDS_INFO.PROVIDER_COMPANY_CODE)}>
                                        <Input value={registrationData.providerCompanyCode} disabled />
                                    </FormField>
                                    <FormField label="Recipient Company Code" info={customHelpPanel(CONSTANTS.FIELDS_INFO.RECIPIENT_COMPANY_CODE)}>
                                        <Input value={registrationData.recipientCompanyCode} disabled />
                                    </FormField>
                                    <FormField label="Provider Entity Name">
                                        <Input value={registrationData.providerEntityName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Recipient Entity Name">
                                        <Input value={registrationData.recipientEntityName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Provider Jurisdiction">
                                        <Input value={registrationData.providerJurisdiction ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Recipient Jurisdiction">
                                        <Input value={registrationData.recipientJurisdiction ?? ''} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Registration Data Contributors" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Accounting Owner">
                                        <Input value={registrationData.accountingOwner ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Owner">
                                        <Input value={registrationData.taxOwner ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Created By">
                                        <Input value={registrationData.createdBy} disabled />
                                    </FormField>
                                    <FormField label="Last Updated By">
                                        <Input value={registrationData.lastUpdatedBy} disabled />
                                    </FormField>
                                    <FormField label="Created Date Time">
                                        <Input value={formatDate(new Date(registrationData.createDateTime!))} disabled />
                                    </FormField>
                                    <FormField label="Last Updated Date Time">
                                        <Input value={formatDate(new Date(registrationData.lastUpdatedDateTime!))} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                            <ExpandableSection headerText="Tax Product Details" variant="container" defaultExpanded>
                                <ColumnLayout columns={2}>
                                    <FormField label="Provider Tax Reg Consideration">
                                        <Input value={String(registrationData.providerTaxRegConsideration)} disabled />
                                    </FormField>
                                    <FormField label="Recipient Tax Reg Consideration">
                                        <Input value={String(registrationData.recipientTaxRegConsideration)} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Category Name">
                                        <Input value={registrationData.taxProductCategoryName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Category Id">
                                        <Input value={registrationData.taxProductCategoryId ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Name">
                                        <Input value={registrationData.taxProductName ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Tax Product Id">
                                        <Input value={registrationData.taxProductId ?? ''} disabled />
                                    </FormField>
                                    <FormField label="Export of Services" info={customHelpPanel(CONSTANTS.FIELDS_INFO.EXPORT_OF_SERVICES)}>
                                        <Input value={String(registrationData.exportOfServices)} disabled />
                                    </FormField>
                                </ColumnLayout>
                            </ExpandableSection>
                        </div>
                    </Form> :<></>
                </SpaceBetween>
              </div> :<></>
            }
        </div>
    );
}