import './styles/SearchData.scss';
import React, {useContext} from "react";
import {
    Button,
    Link,
    ColumnLayout,
    Form,
    FormField,
    Header,
    Input,
    StatusIndicator,
    Autosuggest,
} from "@amzn/awsui-components-react";
import {RegistrationData} from "src/models/data-registration/RegistrationData";
import {useHistory} from "react-router-dom";
import ServiceCollection from "src/services/ServiceCollection";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import Select from "@amzn/awsui-components-react/polaris/select";
import {useCollection} from '@amzn/awsui-collection-hooks';
import {SearchRegistrationDataResponse} from "src/models/data-registration/SearchRegistrationDataResponse";
import Constants from "src/utils/constants";
import {formatDate} from "src/utils/DateUtils";
import {getCompanyCodeLabelValueList, getCompanyCodesFromALE} from "src/utils/ALEUtils";
import {GlobalAppContext} from "src/components/App";

interface SelectedOption {
    label: string,
    value: string
}


export default function SearchData(props: { services: ServiceCollection }) {
    const {services} = props;
    const [registrationNumber, setRegistrationNumber] = React.useState('');
    const [providerCompanyCode, setProviderCompanyCode] = React.useState('');
    const [recipientCompanyCode, setRecipientCompanyCode] = React.useState('');
    const [approvalWorkflowStage, setApprovalWorkflowStage] = React.useState({label: 'None', value: 'None'});
    const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
    const [searchRegistrationDataListMap] = React.useState(new Map<number, RegistrationData[]>());
    const [nextToken, setNextToken] = React.useState('');
    const [loadingRegistrationData, setIsLoadingRegistrationData] = React.useState(false);
    const [searchDone, setIsSearchDone] = React.useState(false);
    const [loadingCompanyCodes, setLoadingCompanyCodes] = React.useState(false);
    const [fetchCompanyCodesErrorMessage, setFetchCompanyCodesErrorMessage] = React.useState('');
    const history = useHistory();
    // We are fetching fixed number of records in a single call and when user
    // clicks on next page, subsequent records are fetched.
    const pageSize: number = 5;

    const {companyCodes, setCompanyCodes} = useContext(GlobalAppContext);

    const stages = Constants.APPROVAL_WORKFLOW_STAGES;

    const {items, collectionProps} = useCollection(
      searchRegistrationDataListMap.get(currentPageIndex) ? searchRegistrationDataListMap.get(currentPageIndex)! : [],
      {
          sorting: {}
      }
    );

    const paginationLinks = [];
    for (let pageNumber of searchRegistrationDataListMap.keys()) {
        paginationLinks.push(
          <Link data-id='pagination-link' key={pageNumber} onFollow={() => setCurrentPageIndex(pageNumber)}>
              {pageNumber}
          </Link>
        );
    }

    function getCompanyCodes() {
        // If company codes are already loaded from React context, just return.
        if (companyCodes.length != 0) {
            return;
        }
        getCompanyCodesFromALE(services.aleDataService, setCompanyCodes, setLoadingCompanyCodes, setFetchCompanyCodesErrorMessage);
    }

    async function onSearchButtonClick() {
        // Clear search registration data
        searchRegistrationDataListMap.clear()
        setCurrentPageIndex(0);

        if (registrationNumber.length !== 0) {
            await searchByRegistrationNumber(registrationNumber);
            return;
        }  
        if (approvalWorkflowStage.value !== "None") {
            await searchByApprovalWorkflowStage(approvalWorkflowStage.value);
            return;
        }
        // Search is done using other inputs like companyCodes.
        await searchByOtherInputs();
    }

    async function searchByRegistrationNumber(registrationNumber: string) {
        setIsLoadingRegistrationData(true);
        const {getRegistrationDataResponse, getRegistrationDataError} =
          await services.registrationDataService.getRegistrationData(registrationNumber);
        setIsLoadingRegistrationData(false);

        if (getRegistrationDataError) {
            services.messageService.showErrorAutoDismissBanner(getRegistrationDataError);
            return;
        }

        // The total approval workflow revisions are equal to the current revision.
        getRegistrationDataResponse.approvalWorkflowRevisionCount = getRegistrationDataResponse.approvalWorkflowRevision;

        setIsSearchDone(true);
        history.push({
            pathname: '/show',
            state: getRegistrationDataResponse as RegistrationData
        });
    }

    async function searchByApprovalWorkflowStage(approvalWorkflowStage: string) {
        setIsLoadingRegistrationData(true);
        const {searchByApprovalStageResponse, searchByApprovalStageError} = 
            await services.registrationDataService.searchByApprovalStage(approvalWorkflowStage, pageSize, nextToken);
        setIsLoadingRegistrationData(false);

       handleSearchResponse(searchByApprovalStageResponse, searchByApprovalStageError);
    }

    async function searchByOtherInputs() {
        setIsLoadingRegistrationData(true);
        const {searchRegistrationDataResponse, searchRegistrationDataError} =
          await services.registrationDataService.searchRegistrationData(providerCompanyCode, recipientCompanyCode, pageSize, nextToken);
        setIsLoadingRegistrationData(false);

        handleSearchResponse(searchRegistrationDataResponse, searchRegistrationDataError);
    }

    function handleSearchResponse(searchRegistrationDataResponse: SearchRegistrationDataResponse, searchRegistrationDataError: string) {
        if (searchRegistrationDataError) {
            services.messageService.showErrorAutoDismissBanner(searchRegistrationDataError);
            return;
        }

        if (searchRegistrationDataResponse.registrationDataList.length > 0) {
            searchRegistrationDataListMap.set(searchRegistrationDataListMap.size + 1, searchRegistrationDataResponse.registrationDataList);
            setCurrentPageIndex(searchRegistrationDataListMap.size);
        }

        // Set next token if present for subsequent request
        if (searchRegistrationDataResponse.nextToken) {
            setNextToken(searchRegistrationDataResponse.nextToken)
        } else {
            setNextToken('');
        }
        setIsSearchDone(true);
    }

    return (
      <div>
          <Form
            actions={
                <Button data-testid="search-data-submit-button" variant="primary" onClick={onSearchButtonClick}
                        disabled={registrationNumber.trim().length == 0
                          && (providerCompanyCode.trim().length == 0 || recipientCompanyCode.trim().length == 0) &&
                          approvalWorkflowStage.value == "None"}>
                    Search
                </Button>
            }
            header={<Header variant="h1">Search Registration Data</Header>}
          >
              <ColumnLayout variant="text-grid" columns={4}>
                  <FormField label="Registration Number">
                      <Input data-testid="search-registration-number-input" value={registrationNumber}
                             onChange={event => setRegistrationNumber(event.detail.value)} placeholder="Enter registration number"
                             disabled={providerCompanyCode.trim().length != 0 || recipientCompanyCode.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                             type="search" inputMode="search" />
                  </FormField>
                  <FormField label="Provider Company Code" constraintText={
                      providerCompanyCode.trim().length == 0 && recipientCompanyCode.trim().length != 0 ? 'Required' : ''}>
                      <Autosuggest data-testid="search-provider-company-code-input"
                                   value={providerCompanyCode}
                                   options={getCompanyCodeLabelValueList(companyCodes)}
                                   onFocus={() => getCompanyCodes()}
                                   loadingText='Loading company codes'
                                   statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                   errorText={fetchCompanyCodesErrorMessage}
                                   onChange={({detail}) => setProviderCompanyCode(detail.value)}
                                   enteredTextLabel={value => `Use: "${value}"`}
                                   disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                                   virtualScroll
                                   empty="No matches found"
                      />
                  </FormField>
                  <FormField label="Recipient Company Code" constraintText={
                      recipientCompanyCode.trim().length == 0 && providerCompanyCode.trim().length != 0 ? 'Required' : ''}>
                      <Autosuggest data-testid="search-recipient-company-code-input"
                                   value={recipientCompanyCode}
                                   options={getCompanyCodeLabelValueList(companyCodes)}
                                   onFocus={() => getCompanyCodes()}
                                   loadingText='Loading company codes'
                                   statusType={loadingCompanyCodes ? 'loading' : (fetchCompanyCodesErrorMessage ? 'error' : 'finished')}
                                   errorText={fetchCompanyCodesErrorMessage}
                                   onChange={({detail}) => setRecipientCompanyCode(detail.value)}
                                   enteredTextLabel={value => `Use: "${value}"`}
                                   disabled={registrationNumber.trim().length != 0 || approvalWorkflowStage.value !== "None"}
                                   virtualScroll
                                   empty="No matches found"
                      />
                  </FormField>
                  <FormField label="Approval Workflow Stage">
                    <Select data-testid="approval-workflow-stage-input" selectedOption={approvalWorkflowStage} 
                      onChange={({detail: {selectedOption}}) => setApprovalWorkflowStage(selectedOption as SelectedOption)} 
                      options={[
                          {label: "None", value: "None"},
                          {label: stages.DRAFT, value: stages.DRAFT},
                          {label: stages.IN_ACCOUNTING_APPROVAL, value: stages.IN_ACCOUNTING_APPROVAL},
                          {label: stages.IN_TAX_APPROVAL, value: stages.IN_TAX_APPROVAL},
                          {label: stages.ACTIVE, value: stages.ACTIVE},
                          {label: stages.INACTIVE, value: stages.INACTIVE},
                          {label: stages.CANCELLED, value: stages.CANCELLED}
                      ]} placeholder="Choose a stage" disabled={registrationNumber.trim().length !== 0 ||
                      providerCompanyCode.trim().length !== 0 || recipientCompanyCode.trim().length !== 0}></Select>
                  </FormField>
                </ColumnLayout>
          </Form>

          <br/>
          <br/>

          {loadingRegistrationData ?
            <StatusIndicator type="loading"> Loading Registration Data </StatusIndicator> :
            <div>
                {searchDone ?
                  <div>
                      <Table data-testid="registration-datalist-table"
                             {...collectionProps}
                             columnDefinitions={[
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.REGISTRATION_NUMBER,
                                     header: "Registration Number",
                                     cell: registrationData => <Link onFollow={() => searchByRegistrationNumber(registrationData.registrationNumber!)}>
                                         {registrationData.registrationNumber}
                                     </Link>,
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.REGISTRATION_NUMBER
                                 },
                                 {
                                    id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                                    header: "Approval Stage",
                                    cell: registrationData => registrationData.approvalWorkflowStage,
                                    sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.APPROVAL_WORKFLOW_STAGE
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ATP_CALCULATION,
                                     header: "ATP Calculation",
                                     cell: registrationData => String(registrationData.atpCalculation),
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ATP_CALCULATION
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                                     header: "Tax Product Category Name",
                                     cell: registrationData => registrationData.taxProductCategoryName,
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_CATEGORY_NAME
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_NAME,
                                     header: "Tax Product Name",
                                     cell: registrationData => registrationData.taxProductName,
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_NAME
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ACCOUNTING_OWNER,
                                     header: "Accounting Owner",
                                     cell: registrationData => registrationData.accountingOwner,
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ACCOUNTING_OWNER
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_OWNER,
                                     header: "Tax Owner",
                                     cell: registrationData => registrationData.taxOwner,
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_OWNER
                                 },
                                 {
                                     id: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.LAST_UPDATED_DATE_TIME,
                                     header: "Last Updated Date Time",
                                     cell: registrationData => formatDate(new Date(registrationData.lastUpdatedDateTime!)),
                                     sortingField: Constants.SEARCH_REGISTRATION_DATA_COLUMNS.LAST_UPDATED_DATE_TIME
                                 }
                             ]}
                             items={items}
                             resizableColumns
                             visibleColumns={[
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.REGISTRATION_NUMBER,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.APPROVAL_WORKFLOW_STAGE,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ATP_CALCULATION,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_CATEGORY_NAME,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_PRODUCT_NAME,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.ACCOUNTING_OWNER,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.TAX_OWNER,
                                 Constants.SEARCH_REGISTRATION_DATA_COLUMNS.LAST_UPDATED_DATE_TIME,
                             ]}
                             empty={
                                 <Box textAlign="center" color="inherit"> No Registration data found </Box>
                             }
                             header={
                                 <>
                                     <Header>
                                         Registration Data
                                         <div>
                                             {paginationLinks}
                                         </div>
                                     </Header>
                                 </>
                             }
                      />
                      {nextToken ?
                        <div style={{float: 'right', margin:'15px 0 0 0'}}>
                            <Link data-testid="next-page-link" onFollow={() => {
                                approvalWorkflowStage.value !== 'None' ? 
                                    searchByApprovalWorkflowStage(approvalWorkflowStage.value) : searchByOtherInputs();
                                }}> Next Page </Link>
                        </div> : <></>
                      }
                  </div>
                  : <></>
                }
            </div>
          }
      </div>
    );
}