import React, {Dispatch, SetStateAction} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import SearchData from "src/components/data-registration/SearchData";
import CustomAppLayout from "src/components/CustomAppLayout";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import ICRSAction from "src/models/ICRSAction";
import {GlobalState, initialState} from "src/services/global/GlobalState";
import {GLOBAL_ACTIONS, globalReducer} from "src/services/global/GlobalReducer";
import ServiceCollection from "src/services/ServiceCollection";
import {UserProfile} from "src/models/UserProfile";
import CONSTANTS from "src/utils/constants";
import CreateData from "src/components/data-registration/CreateData";
import ShowData from "src/components/data-registration/ShowData";
import EditData from "src/components/data-registration/EditData";
import {Subcategory} from "src/models/GetMDMDataResponse";

/**
 * Defines the properties available inside the global context
 */
export type GlobalContextType = {
    globalState: GlobalState,
    globalDispatch: React.Dispatch<ICRSAction>,
    isViewer: boolean,
    // MDM props
    categoryNameToIdMapping: Map<string, string>,
    setCategoryNameToIdMapping: Dispatch<SetStateAction<Map<string, string>>>,
    categorySubcategoriesMapping: Map<string, Subcategory[]>,
    setCategorySubcategoriesMapping: Dispatch<SetStateAction<Map<string, Subcategory[]>>>,
    // ALE props
    companyCodes: string[],
    setCompanyCodes: Dispatch<SetStateAction<string[]>>
}

/**
 * It takes care of "injecting" the context value in the children
 * @param props Context provider props
 * @returns The children of this functional component wrapped with the ContextProvided value
 */
const GlobalContextProvider = (props: any) => {
    const { state, dispatch, isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping,
        categorySubcategoriesMapping, setCategorySubcategoriesMapping, companyCodes, setCompanyCodes, children } = props;
    const providerValue = React.useMemo(() => ({
        globalState: state,
        globalDispatch: dispatch,
        isViewer: isViewer,
        categoryNameToIdMapping: categoryNameToIdMapping,
        setCategoryNameToIdMapping: setCategoryNameToIdMapping,
        categorySubcategoriesMapping: categorySubcategoriesMapping,
        setCategorySubcategoriesMapping: setCategorySubcategoriesMapping,
        companyCodes: companyCodes,
        setCompanyCodes: setCompanyCodes
    }), [state, dispatch, isViewer, categoryNameToIdMapping, setCategoryNameToIdMapping,
        categorySubcategoriesMapping, setCategorySubcategoriesMapping, companyCodes, setCompanyCodes]);
    return (
        <GlobalAppContext.Provider value={providerValue}>
            {children}
        </GlobalAppContext.Provider>
    );
}
/**
 * The global context being shared to any component that needs it
 */
export const GlobalAppContext = React.createContext(null as unknown as GlobalContextType);

export default function App(props: { services: ServiceCollection, userProfile: UserProfile }) {
    const { services, userProfile } = props;

    // A user is a viewer if it only has view permissions
    const isViewer: boolean = userProfile.roles.length == 1 && userProfile.roles[0] === CONSTANTS.ACCESS_ROLES.VIEWER;
    const isTaxUser: boolean = userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.TAX_USER, 0) || userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ADMIN, 0);
    const isAccountingUser: boolean = userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ACCOUNTING_USER, 0) || userProfile.roles.includes(CONSTANTS.ACCESS_ROLES.ADMIN, 0);
    const userAlias: string = userProfile.alias;


    const [globalState, globalDispatch] = useReducerWithLogger(globalReducer, initialState);
    services.messageService.setupForBanner(globalDispatch, GLOBAL_ACTIONS.ADD_BANNER_MESSAGE, GLOBAL_ACTIONS.REMOVE_BANNER_MESSAGE);

    // MDM props
    const [categoryNameToIdMapping, setCategoryNameToIdMapping] = React.useState(new Map<string, string>());
    const [categorySubcategoriesMapping, setCategorySubcategoriesMapping] = React.useState(new Map<string, Subcategory[]>());

    // ALE props
    const[companyCodes, setCompanyCodes] = React.useState([]);

    return (
        <GlobalContextProvider state={globalState}
                               dispatch={globalDispatch}
                               isViewer={isViewer}
                               categoryNameToIdMapping={categoryNameToIdMapping}
                               setCategoryNameToIdMapping={setCategoryNameToIdMapping}
                               categorySubcategoriesMapping={categorySubcategoriesMapping}
                               setCategorySubcategoriesMapping={setCategorySubcategoriesMapping}
                               companyCodes={companyCodes}
                               setCompanyCodes={setCompanyCodes}
        >
            <CustomAppLayout userProfile={userProfile} >
                <Switch>
                    <Route exact path="/">
                        <SearchData services={services} />
                    </Route>
                    { !isViewer &&
                        <Route exact path="/create">
                            <CreateData services={services} userAlias={userProfile.alias} />
                        </Route>
                    }
                    <Route exact path="/show">
                        <ShowData services={services} userAlias={userAlias} isAccountingUser={isAccountingUser} isTaxUser={isTaxUser}/>
                    </Route>
                    <Route exact path="/edit">
                        <EditData services={services} userAlias={userProfile.alias} />
                    </Route>
                    <Redirect path="*" to="/" />
                </Switch>
            </CustomAppLayout>
        </GlobalContextProvider>
    );
}