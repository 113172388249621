import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import {GetMDMDataResponse} from "src/models/GetMDMDataResponse";

export default class MDMDataService {

     async getMDMData() {
         let getMDMDataResponse = undefined as unknown as GetMDMDataResponse;
         let getMDMDataError = '';

         try {
             const response = await apiService.getMDMData();
             getMDMDataResponse = await response.json() as GetMDMDataResponse;
         } catch (ex) {
             getMDMDataError = ErrorUtils.getMessage(ex);
         }

         return {
             getMDMDataResponse,
             getMDMDataError
         }
    }
}


