export interface RegistrationData {
    registrationNumber?: string;
    approvalWorkflowStage?: string;
    approvalWorkflowRevision?: number;
    approvalWorkflowRevisionCount?: number;
    atpCalculation: boolean;
    currency?: string;
    effectiveFromDate?: string;
    effectiveToDate?: string;
    workbookName?: string;
    beat?: string;
    description?: string;
    comments?: string;
    providerCompanyCode: string;
    providerEntityName?: string;
    providerJurisdiction?: string;
    recipientCompanyCode: string;
    recipientEntityName?: string;
    recipientJurisdiction?: string;
    providerTaxRegConsideration: boolean;
    recipientTaxRegConsideration: boolean;
    accountingOwner?: string;
    taxOwner?: string;
    taxProductId?: string;
    taxProductName?: string;
    taxProductCategoryId?: string;
    taxProductCategoryName?: string;
    exportOfServices: boolean;
    accountingApprover?: string;
    taxApprover?: string;
    cancelledBy?: string;
    deactivatedBy?: string;
    reactivatedBy?: string;
    createdBy: string;
    lastUpdatedBy: string;
    createDateTime?: string;
    lastUpdatedDateTime?: string;
}

export function isEquals(firstRegistrationData: RegistrationData, secondRegistrationData: RegistrationData) {
    if (firstRegistrationData.registrationNumber === secondRegistrationData.registrationNumber
        && firstRegistrationData.atpCalculation === secondRegistrationData.atpCalculation
        && firstRegistrationData.currency === secondRegistrationData.currency
        && firstRegistrationData.effectiveFromDate === secondRegistrationData.effectiveFromDate
        && firstRegistrationData.effectiveToDate === secondRegistrationData.effectiveToDate
        && firstRegistrationData.workbookName === secondRegistrationData.workbookName
        && firstRegistrationData.beat === secondRegistrationData.beat
        && firstRegistrationData.description === secondRegistrationData.description
        && firstRegistrationData.comments === secondRegistrationData.comments
        && firstRegistrationData.providerCompanyCode === secondRegistrationData.providerCompanyCode
        && firstRegistrationData.providerEntityName === secondRegistrationData.providerEntityName
        && firstRegistrationData.providerJurisdiction === secondRegistrationData.providerJurisdiction
        && firstRegistrationData.recipientCompanyCode === secondRegistrationData.recipientCompanyCode
        && firstRegistrationData.recipientEntityName === secondRegistrationData.recipientEntityName
        && firstRegistrationData.recipientJurisdiction === secondRegistrationData.recipientJurisdiction
        && firstRegistrationData.providerTaxRegConsideration === secondRegistrationData.providerTaxRegConsideration
        && firstRegistrationData.recipientTaxRegConsideration === secondRegistrationData.recipientTaxRegConsideration
        && firstRegistrationData.accountingOwner === secondRegistrationData.accountingOwner
        && firstRegistrationData.taxOwner === secondRegistrationData.taxOwner
        && firstRegistrationData.taxProductCategoryId === secondRegistrationData.taxProductCategoryId
        && firstRegistrationData.taxProductCategoryName === secondRegistrationData.taxProductCategoryName
        && firstRegistrationData.taxProductId === secondRegistrationData.taxProductId
        && firstRegistrationData.taxProductName === secondRegistrationData.taxProductName
        && firstRegistrationData.exportOfServices === secondRegistrationData.exportOfServices) {
        return true;
    }
    return false;
}